import React from 'react';
import queryString from 'query-string';
import { authenticationService } from '_services/authentication.service';
import 'LoginPage/LoginPage.css';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            auth_code: '',
            access_token: null,
            data: null,
        }
        this.handleLoad = this.handleLoad.bind(this);
        this.getTokenFromCode = this.getTokenFromCode.bind(this);
    }


    callCognitoUI(curLocation) {

    }


    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
    }



    handleLoad() {
        const values = queryString.parse(this.props.location.search);
        let codeVal = values.code;
        if (typeof codeVal !== 'undefined' && codeVal !== null && codeVal !== "" && codeVal !== this.state.auth_code) {
            this.getTokenFromCode(codeVal);
        }
        this.setState({
            auth_code: codeVal
        });
    }



    getTokenFromCode(code) {
        authenticationService.login(code)
            .then(
                user => {
                    const { from } = this.state.from || this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                },
                error => {

                }
            );
    }

    render() {
        return (
            <div>
                <h2>Login</h2>
                <p>Code: {this.state.auth_code}</p>
                <a target="_self" href={"https://auth.scmt.seg.dev.invictusic.engineering/login?client_id=7emimf108bd8rfo4dv461117vc&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=" + window.location.href.split('?')[0]}>
                    Please Let me log in!</a>
            </div>
        )
    }
}

export { LoginPage }; 