import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Logo from 'assets/invictusLogo400x400.png';
import {LoginComponent} from 'LoginComponent/LoginComponent';

import 'components/Header.css';



export default class Header extends Component{
	render(){
		return(
			<React.Fragment>
				<div className="header">
					<div className="title-box">
					<a href="/"><img className="inv-logo" src={Logo} alt=""/></a>&nbsp;
					<span className="main-title">NIST 800-53 Rev.4 Security Controls</span>
					<LoginComponent/>
					</div>
					<nav className="navlink">
						<ul>
							<li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
							<li><NavLink to="/authed/SecurityControls" activeClassName="active">Security Controls</NavLink></li>
                            
						</ul>
					</nav>
				</div>
			</React.Fragment>
		)
	}
}