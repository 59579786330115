import React, {Component} from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import 'authed/SecurityControls.css';
import { ExportCSV } from 'authed/ExportCSV';
import { authenticationService } from '_services/authentication.service';
import { addAuthCORS } from '_helpers/auth-header';

class SecurityControls extends Component{


	constructor(props){
		super(props);
		//States of the SC component
		// When editing, should use "setstate" command
		this.state = {
            currentUser: authenticationService.currentUserValue,
			fileName: 'controls',
			nistCIAOverlay:{
				nistC: '',
				nistI: '',
				nistA: ''
			},
			piiOverlay: false,
			phiOverlay: false,
			sciOverlay:{
				intA: '',
				intB: '',
				intC: ''
			},
			spaceOverlay: false,
			classifiedOverlay: false,
			posts: [],
			cbxNC: false,
			cbxPII: false,
			cbxPHI: false,
			cbxSCI: false,
			cbxSP: false,
			cbxCF: false
		};
		this.defaultData();
		this.handleNC = this.handleNC.bind(this);
		this.toggleNC = this.toggleNC.bind(this);
		this.handlePII = this.handlePII.bind(this);
		this.togglePII = this.togglePII.bind(this);
		this.handlePHI = this.handlePHI.bind(this);
		this.handleSCI = this.handleSCI.bind(this);
		this.toggleSCI = this.toggleSCI.bind(this);
		this.handleSP = this.handleSP.bind(this);
		this.handleCF = this.handleCF.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	defaultData(){
		//const url = `https://7odv7g3vqf.execute-api.us-east-2.amazonaws.com/Production/MasterSCLambda`;
		// const url = 'https://scmt-test.seg.dev.invictusic.engineering/api';
		const url = window.location.origin + '/api';
		//TODO: validate this is minimum cors requesrt setup necessary
		fetch(url, {
			method: "GET",
			headers: addAuthCORS({'Content-Type': 'application/x-www-form-urlencoded'}),
			credentials: 'include',
			mode: 'cors'
		}).then(response => response.json()).then(posts => {
			this.setState({ posts: posts })
		});
	}
	handleNC(event){
		event.persist();
		this.setState(prevState => ({
			nistCIAOverlay: {
				...prevState.nistCIAOverlay,
				[event.target.name]: event.target.value
			}
		}));
	}
	toggleNC(event){
		event.persist();
		this.setState(prevState => ({
			cbxNC: !prevState.cbxNC
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				nistCIAOverlay: {
					...prevState.nistCIAOverlay,
					nistC: 'L',
					nistI: 'L',
					nistA: 'L'
				}
			}));
		}
		else{
			this.setState(prevState => ({
				nistCIAOverlay: {
					...prevState.nistCIAOverlay,
					nistC: '',
					nistI: '',
					nistA: ''
				}
			}));
		}
	}
	handlePII(event){
		event.persist();
		this.setState({
			piiOverlay: event.target.value			
		});
	}
	togglePII(event){
		event.persist();
		this.setState(prevState => ({
			cbxPII: !prevState.cbxPII
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				piiOverlay: prevState.piiOverlay='L'
			}));
		}
		else{
			this.setState(prevState => ({
				piiOverlay: prevState.piiOverlay=false
			}));
		}
	}
	handlePHI(event){
		event.persist();
		this.setState({
			phiOverlay: event.target.checked			
		});
	}
	handleSCI(event){
		event.persist();
		this.setState(prevState => ({
			sciOverlay: {
				...prevState.sciOverlay,
				[event.target.name]: event.target.checked
			}
		}));
	}
	toggleSCI(event){
		event.persist();
		this.setState(prevState => ({
			cbxSCI: !prevState.cbxSCI
		}));
		if(event.target.checked){
			this.setState(prevState => ({
				sciOverlay: {
					...prevState.sciOverlay,
					intA: true,
					intB: false,
					intC: false
				}
			}));
		}
		else{
			this.setState(prevState => ({
				sciOverlay: {
					...prevState.sciOverlay,
					intA: false,
					intB: false,
					intC: false
				}
			}));
		}
	}
	handleSP(event){
		event.persist();
		this.setState({
			spaceOverlay: event.target.checked			
		});
	}
	handleCF(event){
		event.persist();
		this.setState({
			classifiedOverlay: event.target.checked			
		});
	}
	handleSubmit(event){
		event.preventDefault();
		const { nistC, nistI, nistA } = this.state.nistCIAOverlay;
		const pii = this.state.piiOverlay;
		const phi = this.state.phiOverlay;
		const { intA, intB, intC } = this.state.sciOverlay;
		const sp = this.state.spaceOverlay;
		const cf = this.state.classifiedOverlay;
		const Qstr =  `?conf=${nistC}&integ=${nistI}&avail=${nistA}&pii=${pii}&phi=${phi}&intA=${intA}&intB=${intB}&intC=${intC}&sp=${sp}&cf=${cf}`;
		// const url = `https://7odv7g3vqf.execute-api.us-east-2.amazonaws.com/Production/MasterSCLambda?conf=${nistC}&integ=${nistI}&avail=${nistA}&pii=${pii}&phi=${phi}&intA=${intA}&intB=${intB}&intC=${intC}&sp=${sp}&cf=${cf}`;
		// const url = 'https://scmt-test.seg.dev.invictusic.engineering/api' + Qstr;
		const url = window.location.origin + '/api' + Qstr;
		//TODO: validate this is minimum cors requesrt setup necessary
		fetch(url, {
			method: "GET",
			headers: addAuthCORS({'Content-Type': 'application/x-www-form-urlencoded'}),
			credentials: 'include',
			mode: 'cors'
		})
		.then(response => response.json())
		.then(posts => 
			this.setState({ posts: posts })
		);
	}

	render(){
		return(
			<div>
				<div className="ctrl-chkbx">
					<div className="scheading">
						<h2 className="sctitle2">
							Choose your overlay
						</h2>
					</div>
					<form
						className="form-container-03"
						onSubmit={this.handleSubmit}>
						<table className="cbx-table-03">
							<tbody>
								<tr className="cbx-table-row-01">
									<td className="sc-td1">
										<input
											className="cbx-nistCIA"
											value={this.state.cbxNC}
											onChange={this.toggleNC}
											type="checkbox"
											id="cbx-nistCIA-id"/>
										<label
											htmlFor="cbx-nistCIA-id">
											NIST CIA
										</label>
										<select
											className="selection-drop"
											name="nistC"
											onChange={this.handleNC}
											disabled={!this.state.cbxNC}
											value={this.state.nistCIAOverlay.nistC}
										>
											<option value="L">Low</option>
											<option value="M">Mod</option>
											<option value="H">High</option>
										</select>
										<select
											className="selection-drop"
											name="nistI"
											onChange={this.handleNC}
											disabled={!this.state.cbxNC}
											value={this.state.nistCIAOverlay.nistI}
										>
											<option value="L">Low</option>
											<option value="M">Mod</option>
											<option value="H">High</option>
										</select>
										<select
											className="selection-drop"
											name="nistA"
											onChange={this.handleNC}
											disabled={!this.state.cbxNC}
											value={this.state.nistCIAOverlay.nistA}
										>
											<option value="L">Low</option>
											<option value="M">Mod</option>
											<option value="H">High</option>
										</select>
										<pre className="tab">C          I          A</pre>
									</td>
									<td className="sc-td3">
										<input
											className="cbx-pii"
											value={this.state.cbxPII}
											onChange={this.togglePII}
											type="Checkbox"
											id="cbx-pii-id"/>
										<label
											htmlFor="cbx-pii-id">
											PII
										</label>
										<select
											className="selection-drop"
											name="pii"
											onChange={this.handlePII}
											disabled={!this.state.cbxPII}
											value={this.state.piiOverlay}
										>
											<option value="L">Low</option>
											<option value="M">Mod</option>
											<option value="H">High</option>
										</select>
									</td>
									<td className="sc-td4">
										<input
											className="cbx-phi"
											value={this.state.cbxPHI}
											onChange={this.handlePHI}
											type="Checkbox"
											id="cbx-phi-id"/>
										<label
											htmlFor="cbx-phi-id">
											PHI
										</label>
									</td>
								</tr>
								<tr className="cbx-table-row-01">
									<td className="sc-td5">
										<div className="cbx-sci-div">
											<input
												className="cbx-sci"
												value={this.state.cbxSCI}
												checked={this.state.cbxSCI}
												onChange={this.toggleSCI}
												type="checkbox"
												id="cbx-sci-id"/>
											<label htmlFor="cbx-sci-id">
												SCI
											</label>
										</div>
										<div className="cbx-sci-int-div">
											<input
												className="cbx-sci-Int"
												name="intA"
												checked={this.state.sciOverlay.intA}
												onChange={this.handleSCI}
												disabled={!this.state.cbxSCI}
												type="checkbox"
												id="cbx-IntA-id"/>
											<label htmlFor="cbx-IntA-id"/>
											<input
												className="cbx-sci-Int"
												name="intB"
												checked={this.state.sciOverlay.intB}
												onChange={this.handleSCI}
												disabled={!this.state.cbxSCI}
												type="checkbox"
												id="cbx-IntB-id"/>
											<label htmlFor="cbx-IntB-id"/>
											<input
												className="cbx-sci-Int"
												name="intC"
												checked={this.state.sciOverlay.intC}
												onChange={this.handleSCI}
												disabled={!this.state.cbxSCI}
												type="checkbox"
												id="cbx-IntC-id"/>
											<label htmlFor="cbx-IntC-id"/>
										</div>
										<pre
											className="tab"
										>
											IntA  IntB  IntC
										</pre>
									</td>
									<td className="sc-td6">
										<input
											className="cbx-sp"
											value={this.state.cbxSP}
											onChange={this.handleSP}
											type="Checkbox"
											id="cbx-sp-id"/>
										<label
											htmlFor="cbx-sp-id">
											Space
										</label>
									</td>
									<td className="sc-td7">
										<input
											className="cbx-cf"
											value={this.state.cbxCF}
											onChange={this.handleCF}
											type="Checkbox"
											id="cbx-cf-id"/>
										<label
											htmlFor="cbx-cf-id">
											Classified
										</label>
									</td>
								</tr>
								<tr className="cbx-table-row-03">
									<td></td><td>
									<ExportCSV
										csvData={this.state.posts}
										fileName={this.state.fileName}
									/>
									<input
										className="submit-btn"
										type="submit"
										value="Submit"
									/>
									</td>
								</tr>
							</tbody>
						</table>
					</form>
				</div>
				<div className="master-table">
					<ReactTable
						// ReactTable Document URL
						// https://www.npmjs.com/package/react-table-6
						className="sc-data"
						data={this.state.posts}
						filterable
						defaultPageSize={20}
						style={{overflow:'wrap'}}
						pagination
						showPaginationTop
						showPageSizeOptions
						pageSizeOptions={[1, 5, 10, 20, 25, 50, 100, 1000, 2000]}
						showPageJump
						columns = {[
						{
							Header: "Control ID",
							accessor: "ControlID",
							sortable: false,
							style: {
								textAlign: "left"
							},
							maxWidth: 140
						},
						{
							Header: "Title",
							accessor: "Title",
							sortable: true,
							filterable: true,
							style: {
								textAlign: "left",
								'whiteSpace': 'unset'
							},
							maxWidth: 500
						},
						{
							Header: "Stated Requirement",
							accessor: "Description",
							sortable: true,
							filterable: true,
							style: {
								textAlign: "left",
								'whiteSpace': 'unset'
							},
							maxWidth: 930
						}
						]}
					/>
				</div>
			</div>
		);
	}
}

export {SecurityControls};