import React, {Component} from 'react';
import { authenticationService } from '_services/authentication.service';
import GoldLogo from 'assets/Invictus-RGB_Full-Logo-Gold.png';
import 'components/Home.css';

export default class Home extends Component{


	constructor(props){
		super(props);
		this.state = {
            currentUser: authenticationService.currentUserValue
		}
	}


    componentDidMount() {
        // userService.getAll().then(users => this.setState({ users }));
	}

	render(){
		return(
			<div id="homebody">
				<p className="welcome">Welcome to the Security Controls Management Tool!<br></br></p>
				<p className="intro">
					The purpose of this application is to allow users to quickly view the relevant<br/>
					NIST 800-53 Security Controls they need to implement in their information system.<br/>
					The application displays the controls based on the user's selection of<br/>
					the CNSSI 1253 overlays that classify their system.<br/>
					The application also allows users to export the selected controls<br/>
					in a file format that can be used in XACTA.
				</p>
				<section className="hero-zone ">
					<div className="hero-video-outer visible-xs visible-sm ">
					<video autoPlay loop muted
					src="https://invictusic.com/wp-content/uploads/2019/07/world-in-world-2.mp4" type="video/mp4" autoPlay="autoplay">
					</video>
					</div>
					<a href="/"><img className="goldlogo" src={GoldLogo} alt=""/></a>&nbsp;
					
					{/* <div class="container">
					<div class="row">
						<div class="content-sm text-center">
							<h1>Turning uncertainty into knowledge </h1>
								<div class="row">
						<div class="content-sm text-center">
							<p><strong>Invictus is a full-spectrum cyber and national security firm that protects the nation’s global defense and critical infrastructure.</strong></p>
						</div>
						</div>
						</div>
					</div>
					</div> */}				
  				</section>
			</div>
		)
	}
}