import React, {Component} from 'react';
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';
import Header from 'components/Header';
import Home from 'components/Home';
import {SecurityControls} from 'authed/SecurityControls';
import { LoginPage } from 'LoginPage/LoginPage';
import { history } from '_helpers/history';
import { authenticationService } from '_services/authentication.service';
import { PrivateRoute } from '_components/PrivateRoute';
import 'App.css';

export default class App extends Component{

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }

    logout() {
        authenticationService.logout();
        history.push('/login');
    }

    render(){
        return(
            <BrowserRouter>
                <div className="app">
                    <Header />
                    <Switch>
                        <PrivateRoute path="/authed/securitycontrols" component={SecurityControls} />
                        <Route exact path="/login" component={LoginPage} />
                        {/* <Route exact path="/" component={Home} /> */}
                        <Route exact path="/*" component={Home} /> {/*TODO: better redirect needed such as https://reacttraining.com/react-router/web/example/auth-workflow*/}
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}