import React from 'react';
import { BehaviorSubject } from 'rxjs';
import queryString from 'query-string';
import { authenticationService } from '_services/authentication.service';
import 'LoginComponent/LoginComponent.css';

class LoginComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            auth_code: '',
            access_token: null,
            data: null,
        }
        this.handleLoad = this.handleLoad.bind(this);
        this.getTokenFromCode = this.getTokenFromCode.bind(this);
        this.logoutForce = this.logoutForce.bind(this);
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
    }



    handleLoad() {
        let codeVal = null;
        if(this.props){
            if(this.props.location){
                if(this.props.location.search){
                    const values = queryString.parse(this.props.location.search);
                    codeVal = values.code;
                }
            }
        }
        //let codeVal = values.code;
        if (typeof codeVal !== 'undefined' && codeVal !== null && codeVal !== "" && codeVal !== this.state.auth_code) {
            this.getTokenFromCode(codeVal);
        }
        this.setState({
            auth_code: codeVal
        });
    }



    getTokenFromCode(code) {
        authenticationService.login(code)
            .then(
                user => {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                },
                error => {

                }
            );
    }

    //TODO: something is wrong to be required to do this.
    logoutForce() { 
        const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        localStorage.removeItem('currentUser');
        currentUserSubject.next(null);
        this.props.history.push(`/home`);
    }

    

    render() {
        const currentUser = authenticationService.currentUserValue;
        let link=<a id="logout" onClick={this.logoutForce} href="/home">Logout</a>;
        if (!currentUser) {
            //link=<span id="login"  >To login visit protected page</span>;
            //link=<a id="login" target="_self" href={"https://auth.scmt.seg.dev.invictusic.engineering/login?client_id=7emimf108bd8rfo4dv461117vc&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri="+window.location.href.split('?')[0]}>Login</a>;
            link=<a id="login" target="_self" href={"https://auth.scmt.seg.dev.invictusic.engineering/login?client_id=7emimf108bd8rfo4dv461117vc&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri="+window.location.href.match(/^.*\//)+ "login"}>Login</a>;
        }
        return (
            <div>
            {link}
            </div>
        )
    }
}

export { LoginComponent }; 