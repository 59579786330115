import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '_helpers/handle-response';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(code) {
        const qUrl = "https://auth.scmt.seg.dev.invictusic.engineering/oauth2/token";
        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: "grant_type=authorization_code&client_id=7emimf108bd8rfo4dv461117vc&redirect_uri="+window.location.href.split('?')[0]+"&code=" + code,
        };
        return fetch(qUrl, settings)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });

}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
